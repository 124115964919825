import React from 'react';
import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { first } from 'underscore';
import AvailabilityCalendar from '../../components/AvailabilityCalendar';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './availability.scss';
import ProgressBar from '../../components/ProgressBar';
import { setSelectedSlot } from '../../core/availability/availabilityActions';

const Availability = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { availabilitySlots, firstAvailableSlot, selectedSlots, resourceValues } = useSelector(state => state?.availability);
    const { selectedServiceId, selectedServiceName } = location.state || {};

    const handleNextClick = () => {
        navigate('/attach-document', { state: { selectedServiceId } });
    };

    const handleSelectedSlot = ({ serviceId, slot }) => {
        const { resources } = slot;
        const updatedSelectSlot = {
            ...selectedSlots[serviceId] || {},
            serviceId,
            date: slot.date,
            startTime: slot.startTime,
            resourceId: resourceValues[first(resources)]?.resourceId,
            resourceName: resourceValues[first(resources)]?.resourceName,
            resourceType: resourceValues[first(resources)]?.resourceType,
            scheduleInfo: slot.isAvailable,
        };
        dispatch(setSelectedSlot(updatedSelectSlot));
    };

    return (
        <div className="availability-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={1} />
            </div>
            <p className="booking-info">
                You are completing the booking for {selectedServiceName}.
            </p>
            <div className="availability-content">
                <p className="instruction">Please select a date for the appointment:</p>
                <AvailabilityCalendar
                    availabilityData={availabilitySlots[selectedServiceId]}
                    firstAvailableSlot={firstAvailableSlot[selectedServiceId]}
                    preSelectedSlot={null}
                    onSelectSlot={(slot) => handleSelectedSlot({ serviceId: selectedServiceId, slot })}
                    gpUser={false} />
                <Button type="primary" className="next-button" onClick={handleNextClick}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default Availability;
