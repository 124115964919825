import React, { useState, useEffect } from 'react';
import { Button, Input, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty } from 'underscore';
import InputMask from 'react-input-mask';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './security.scss';
import { getPatientBookingsDetailsRequest } from '../../core/bookings/bookingsActions';

const Security = () => {
    const [dob, setDob] = useState('');
    const [surname, setSurname] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { messageId, methodToBook } = location.state || {};

    const { modalityData, isFetching } = useSelector((state) => state.bookings);

    const isFormValid = dob.trim() !== '' && surname.trim() !== '' && /^(\d{2})\/(\d{2})\/(\d{4})$/.test(dob);

    const handleNextClick = () => {
        if (isFormValid) {
            dispatch(getPatientBookingsDetailsRequest({ messageId, methodToBook, surname, dob }));
        }
    };

    useEffect(() => {
        if (!isEmpty(modalityData)) {
            navigate('/appointment');
        }
    }, [modalityData]);

    return (
        <div className="security-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="security-overlay" />
            <div className="security-content">
                <div className="security-form">
                    <h1 className="security-title">Security</h1>
                    <p className="security-description">Please confirm your Date of Birth and Surname to access your bookings.</p>
                    <label htmlFor="dob">Date of Birth</label>
                    <InputMask
                        id="dob"
                        mask="99/99/9999"
                        placeholder="dd/mm/yyyy"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}>
                        {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                    <label htmlFor="surname">Surname</label>
                    <Input
                        id="surname"
                        placeholder="Surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)} />
                </div>
                <Button
                    type="primary"
                    className="next-button no-hover"
                    icon={isFetching ? <Spin size="small" /> : null}
                    onClick={handleNextClick}
                    disabled={!isFormValid || isFetching}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default Security;
