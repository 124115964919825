import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import bgSrcDeskt from '../../../assets/images/welcome-background.png';
import icon from '../../../assets/favicon.ico';
import './authLayout.scss';

const { APP_NAME } = require('../../../core/constants').default;

const AuthLayout = ({ children }) => (
    <div className="login-wrap">
        <Helmet
            title={`${APP_NAME}`}
            titleTemplate={`${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' },
            ]}>
            <link rel="shortcut icon" href={icon} />
        </Helmet>
        <div className="login-info">
            <img src={bgSrcDeskt} alt={`${APP_NAME}`} className="bg-image" />
        </div>
        {children}
    </div>
);

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
export default AuthLayout;
