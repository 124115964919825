import React from 'react';
import { Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './beginBooking.scss';
import bgSrcDeskt from '../../assets/images/welcome-background.png';

const BeginBooking = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // mock url
    // const url = '/begin-booking?messageId=39cc7187caa68ad51a8d228c1206d202&methodToBook=2';

    const navigateToSecurity = () => {
        const messageId = searchParams.get('messageId');
        const methodToBook = searchParams.get('methodToBook');
        navigate('/security', { state: { messageId, methodToBook } });
    };

    return (
        <div className="bookings-wrap">
            <div className="bookings-info">
                <img src={bgSrcDeskt} alt="" className="bg-image" />
            </div>
            <div className="booking-page" title="Book your appointment">
                <div className="booking-content">
                    <h1 className="booking-title">Book your appointment</h1>
                    <p className="booking-description">Your Welbeck clinician or GP has begun creating an appointment for you. Please fill out this form to complete your booking.</p>
                    <Button type="primary" className="begin-button" onClick={navigateToSecurity}>Begin</Button>
                </div>
            </div>
        </div>
    );
};

export default BeginBooking;
