import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    POST_AVAILABILITY_REQUEST: null,
    POST_AVAILABILITY_SUCCESS: null,
    POST_AVAILABILITY_FAILURE: null,

    POST_CALENDAR_AVAILABILITY_REQUEST: null,
    POST_CALENDAR_AVAILABILITY_SUCCESS: null,
    POST_CALENDAR_AVAILABILITY_FAILURE: null,

    SET_SELECTED_SLOT: null,
});

export const {
    postAvailabilityRequest,
    postAvailabilitySuccess,
    postAvailabilityFailure,
} = createActions(
    constants.POST_AVAILABILITY_REQUEST,
    constants.POST_AVAILABILITY_SUCCESS,
    constants.POST_AVAILABILITY_FAILURE,
);

export const {
    postCalendarAvailabilityRequest,
    postCalendarAvailabilitySuccess,
    postCalendarAvailabilityFailure,
} = createActions(
    constants.POST_CALENDAR_AVAILABILITY_REQUEST,
    constants.POST_CALENDAR_AVAILABILITY_SUCCESS,
    constants.POST_CALENDAR_AVAILABILITY_FAILURE,
);

export const {
    setSelectedSlot,
} = createActions(
    constants.SET_SELECTED_SLOT,
);
