import React, { useState, useEffect } from 'react';
import { Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { first, isEmpty } from 'underscore';
import Accordion from '../../components/Accordion';
import ProgressBar from '../../components/ProgressBar';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './appointment.scss';
import { CalendarIcon, ClockIcon, IconTickFilled, FileOutlined } from '../../components/Elements/CustomSVGIcon';
import { postCalendarAvailabilityRequest, setSelectedSlot } from '../../core/availability/availabilityActions';
import useSubmitBooking from '../../core/hooks/useSubmitBooking';

const Appointment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [basketData, setBasketData] = useState([]);
    const { submitBookingRequest } = useSubmitBooking();
    const { modalityData, isFetching: isCompleteBookingFetching, serviceIds } = useSelector((state) => state.bookings);
    const { firstAvailableSlot, isFetching, resourceValues, selectedSlots } = useSelector(state => state?.availability);
    const today = new Date();

    useEffect(() => {
        if (Object.keys(serviceIds).length > 0 && isEmpty(selectedSlots)) {
            Object.keys(serviceIds).forEach((serviceId) => {
                dispatch(postCalendarAvailabilityRequest({
                    serviceId: serviceIds[serviceId].join(','),
                    startDate: today,
                }));
            });
        }
    }, [serviceIds]);

    useEffect(() => {
        if (isEmpty(modalityData) || isEmpty(firstAvailableSlot) || isFetching) {
            return;
        }
        const patientData = modalityData.map((modality) => {
            const servicesId = modality.portalServices.map(serviceItem => serviceItem.portalServiceRequestId);
            const subServices = modality.portalServices.map(serviceItem => serviceItem.portalServiceRequestName).join('\n');
            const selectedSlot = selectedSlots[servicesId];
            const firstSlot = firstAvailableSlot[servicesId];

            if (!firstSlot) {
                return null;
            }

            const formattedDate = moment(selectedSlot?.date || firstSlot?.date).format('dddd Do MMMM');
            const hasCompleted = selectedSlots[servicesId]?.scheduleInfo;
            return {
                id: modality.modalityId,
                title: modality.modalityName,
                subServices,
                servicesId,
                content: {
                    date: formattedDate,
                    startTime: selectedSlots[servicesId]?.startTime || firstAvailableSlot[servicesId].slot,
                    firstAvailableDate: moment(firstAvailableSlot[servicesId].date).format('dddd Do MMMM'),
                    firstAvailableTime: firstAvailableSlot[servicesId].slot,
                    documents: selectedSlot?.mediaInfo,
                },
                hasCompleted,
            };
        }).filter(Boolean);
        setBasketData(patientData);

    }, [firstAvailableSlot]);

    const handleBookAppointment = (id) => {
        setBasketData((prevData) => prevData.map((item) => (item.id === id ? { ...item, hasCompleted: true } : item)));
        const serviceId = serviceIds[id];
        const { resourceId, resourceName, resourceType } = resourceValues[first(firstAvailableSlot[serviceId].resources)];
        const selectedSlot = {
            serviceId,
            startTime: firstAvailableSlot[serviceId].slot,
            date: firstAvailableSlot[serviceId].date,
            scheduleInfo: firstAvailableSlot[serviceId].isAvailable,
            resourceId,
            resourceName,
            resourceType,
        };
        dispatch(setSelectedSlot(selectedSlot));
    };

    const handleCompleteBooking = () => {
        if (isCompleteBookingFetching) {
            return;
        }
        submitBookingRequest();
        navigate('/booking-result');
    };

    const handleSeeAvailability = (selectedService) => {
        navigate('/availability', { state: { selectedServiceId: selectedService.servicesId, selectedServiceName: selectedService.title } });
    };

    const areAllCompleted = basketData.every((item) => item.hasCompleted);

    const renderItem = ({ item }) => (
        <div className="accordion-item">
            <div className="accordion-title">
                <h3>{item.title}</h3>
                <pre><span>{item.subServices}</span></pre>
                {item.hasCompleted && (
                    <div className="completed-appointment">
                        <span className="completed-appointment-date">
                            <IconTickFilled className="icon" />
                            {item.content.startTime} on {item.content.date}
                        </span>
                    </div>
                )}
            </div>
            {item.hasCompleted && item.content.documents && (
                <div className="submitted-documents">
                    {item.content.documents.map((doc) => (
                        <div key={doc.id} className="document-item">
                            <FileOutlined className="document-icon" />
                            {doc.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    const renderContent = ({ item }) => (
        <div className="accordion-content">
            <p className="next-appointment">Next Available Appointment</p>
            <div className="appointment-info">
                <span className="appointment-date">
                    <CalendarIcon className="icon" />
                    {item.content.firstAvailableDate}
                </span>
                <span className="appointment-time">
                    <ClockIcon className="icon" />
                    {item.content.firstAvailableTime}
                </span>
            </div>
            <div className="button-container">
                <Button type="default" className="book-appointment-button" onClick={() => handleBookAppointment(item.id)}>
                    Book Appointment
                </Button>
                <Button type="default" className="see-availability-button" onClick={() => handleSeeAvailability(item)}>
                    See Other Availability
                </Button>
            </div>
        </div>
    );

    return (
        <div className="appointment-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={1} />
            </div>
            <div className="appointments">
                <h1>Appointments</h1>
                <p>Here are the appointments that have been referred to you. Please complete the booking for each appointment.</p>
                {isFetching ? <Spin size="large" />
                    : (
                        <Accordion
                            data={basketData}
                            renderItem={renderItem}
                            renderContent={renderContent}
                            multiOpen />
                    )}
            </div>
            <Button
                type="primary"
                className="complete-booking-button"
                disabled={!areAllCompleted}
                onClick={handleCompleteBooking}>
                Complete Booking
            </Button>
        </div>
    );
};

export default Appointment;
