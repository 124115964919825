/* eslint-disable */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import lockr from 'lockr';
import { notification } from 'antd';
import { isUndefined } from 'underscore';

import NotFound from '../../pages/NotFound';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
} = require('../constants').default;

const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);
    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const parsedAuthDate = new Date(authDate);
            const now = new Date();
            const differenceMilliseconds = now - parsedAuthDate;
            const differenceMinutes = Math.floor(differenceMilliseconds / 1000 / 60);
            if (differenceMinutes >= LOCALSTORAGE_EXPIRES) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};

const LogoutRoute = ({ exact, path, persistor }) => {
    lockr.rm(AUTH_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES);

    localStorage.clear();
    lockr.flush();
    persistor.flush();
    persistor.purge();
    window.location.href = '/';
}

const CoreRouter = ({ routes }) => (
    <Routes>
        {routes.map((route, index) => (
            route.type === 'auth' ?
                <Route key={index} exact path={route.path} element={
                    onlyAuthorisedAllowed() ? <route.component /> : <Navigate to="/" />
                } role={route.role} allowedOnlyRoles={route.allowedOnlyRoles} />
                : <Route key={index} exact path={route.path} element={<route.component />} />
            ))}
        <Route element={<NotFound />} />
    </Routes>
);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    LogoutRoute,
};
