import React from 'react';
import { Button, Spin } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './bookingResult.scss';

const BookingResult = () => {
    const { bookingResponses, isFetching, error } = useSelector(state => state?.bookings);
    const hasFailureStatus = bookingResponses?.modality?.some((item) => item.portalServices.some((service) => service.serviceStatus !== 'Success'));
    const failedServices = bookingResponses?.modality?.flatMap(item => item.portalServices.filter(service => service.serviceStatus !== 'Success'));
    const failureServiceNames = failedServices?.map(service => service.portalServiceRequestName).join(', ');

    const contactWelbeck = () => {
        console.log('contact');
    };

    return (
        <div className="booking-result-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            {isFetching ? <Spin size="large" /> : (
                <div className="result-container">
                    {!hasFailureStatus && !error && (
                        <>
                            <CheckCircleOutlined className="result-icon success" />
                            <h1 className="result-title">Booking Successful</h1>
                            <p className="result-message">
                                All appointments have successfully been booked at your requested times.
                            </p>
                        </>
                    )}
                    {hasFailureStatus && (
                        <>
                            <ExclamationCircleOutlined className="result-icon error" />
                            <h1 className="result-title">Booking Unsuccessful</h1>
                            <p className="result-message">
                                We&apos;re sorry, there was an issue when completing one of the appointments in your booking.
                            </p>
                            <p className="appointment-details">
                                <strong>{failureServiceNames}</strong>
                            </p>
                            <p className="result-message">
                                OneWelbeck will be in touch to rearrange the appointment with you.
                            </p>
                            <Button type="default" className="contact-button" onClick={contactWelbeck}>
                                Contact OneWelbeck
                            </Button>
                        </>
                    )}
                    {error && (
                        <>
                            <ExclamationCircleOutlined className="result-icon error" />
                            <h1 className="result-title">Booking Unsuccessful</h1>
                            <p className="result-message">
                                We&apos;re sorry, there was an issue when completing one of the appointments in your booking.
                            </p>
                            <p className="result-message">
                                OneWelbeck will be in touch to rearrange the appointment with you.
                            </p>
                            <Button type="default" className="contact-button" onClick={contactWelbeck}>
                                Contact OneWelbeck
                            </Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default BookingResult;
