/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input, message } from 'antd';
import InputMask from 'react-input-mask';
import './requestModal.scss';

const RequestModal = ({ visible, onClose, onSubmit }) => {
    /* eslint-disable no-unused-vars */
    const [phoneNumber, setPhoneNumber] = useState('');
    /* eslint-disable no-unused-vars */
    const [notes, setNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {
        if (!phoneNumber) {
            message.error('Phone number is required');
            return;
        }
        setIsSubmitting(true);
        setTimeout(() => {
            const isSuccess = Math.random() > 0.5;
            onSubmit(isSuccess);
            setIsSubmitting(false);
        }, 1000);
    };

    const handleCallDirectly = () => {
        window.location.href = 'tel:1234567890';
    };

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            footer={null}
            centered
            className="request-modal">
            <div className="request-modal-content">
                <p>
                    For further availability, please enter any details surrounding the
                    booking and our team will get back to you as soon as possible to
                    complete the booking for you.
                </p>
                <InputMask
                    mask="999-999-9999"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}>
                    {(inputProps) => <Input {...inputProps} placeholder="Phone Number" className="input-field" />}
                </InputMask>
                <label className="input-label">Notes</label>
                <Input.TextArea
                    placeholder="e.g. reason for urgent request"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="input-field"
                    rows={4} />
                <Button
                    type="primary"
                    block
                    className="request-button"
                    onClick={handleSubmit}
                    loading={isSubmitting}>
                    Request a Call Back
                </Button>
                <Button type="default" block className="call-button" onClick={handleCallDirectly}>
                    Call Directly
                </Button>
            </div>
        </Modal>
    );
};

RequestModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default RequestModal;
