import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Upload, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'underscore';
import { UploadIcon } from '../../components/Elements/CustomSVGIcon';
import ProgressBar from '../../components/ProgressBar';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './attachDocument.scss';
import { PATIENT_LEVEL } from '../../core/utils/values';
import { setSelectedSlot } from '../../core/availability/availabilityActions';

const { TextArea } = Input;

const AttachDocument = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [base64String, setBase64String] = useState('');
    const [mediaId, setMediaId] = useState(null);
    const [mediaData, setMediaData] = useState([]);
    const dispatch = useDispatch();
    const { selectedSlots } = useSelector(state => state.availability);
    const { selectedServiceId } = location.state || {};

    useEffect(() => {
        const keys = !isEmpty(selectedSlots) && selectedSlots[selectedServiceId]?.mediaInfo?.map(media => media.id) || [];
        const maxKey = Math.max(...keys);
        if (maxKey >= 100) {
            setMediaId(maxKey + 1);
        } else {
            setMediaId(100);
        }
    }, [selectedSlots]);

    const getBase64 = (file) => new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleNextClick = () => {
        // will be removing this console.log
        console.log({ base64String });
        navigate('/appointment');
    };

    const updateSelectedSlot = ({ mediaInfo }) => {
        const existingMediaInfo = (selectedSlots[selectedServiceId] && selectedSlots[selectedServiceId].mediaInfo) || [];

        const updatedSelectSlot = {
            ...selectedSlots[selectedServiceId] || {},
            serviceId: selectedServiceId,
            mediaInfo: [
                ...existingMediaInfo,
                { id: mediaInfo.id,
                    name: mediaInfo.name },
            ],
        };
        dispatch(setSelectedSlot(updatedSelectSlot));
    };

    const handleUpload = async (info) => {
        const file = info.file.originFileObj;
        if (info.file.status === 'done') {
            try {
                const base64 = await getBase64(file);
                const mediaInfo = {
                    name: file.name,
                    type: file.type,
                    field: `${PATIENT_LEVEL},0,${mediaId}`,
                    id: mediaId,
                };
                setMediaData([...mediaData, mediaInfo]);
                updateSelectedSlot({ mediaInfo });
                setBase64String(base64);
                message.success('Document uploaded successfully');
            } catch (error) {
                message.error('Something went wrong while uploading the document');
            }
        }
    };

    return (
        <div className="attach-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={1} />
            </div>
            <div className="document-container">
                <p className="booking-info">
                    If there are any documents you need to provide for this appointment - e.g. referral letter - or have any notes to mention, please do so here.
                </p>
                <div className="document-upload-container">
                    <Upload accept=".png,.pdf"
                        multiple={false}
                        className="document-upload"
                        showUploadList={false}
                        // eslint-disable-next-line no-unused-vars
                        customRequest={({ file, onSuccess }) => {
                        // Simulate an async request
                            setTimeout(() => {
                                onSuccess('ok');
                            }, 0);
                        }}
                        onChange={handleUpload}>
                        <div className="upload-area">
                            <Button icon={<UploadIcon />} type="link" className="form-upload">Attach Form</Button>
                            <p className="upload-description">You can attach a PNG or PDF of your referral letter.</p>
                        </div>
                    </Upload>
                </div>
                <div className="notes">
                    <p className="notes-title">Notes (Optional)</p>
                    <TextArea placeholder="e.g. name of your preferred referral doctor" rows={4} />
                </div>
                <Button type="primary" className="next-button" onClick={handleNextClick}>Next</Button>
            </div>
        </div>
    );
};

export default AttachDocument;
